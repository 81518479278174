<template>
  <v-container>
    <h1>Page</h1>
    <v-row>
      <v-col cols="12" xl="5" lg="5" md="5" sm="12">
        <v-text-field v-model="page.name" label="Name"></v-text-field>
      </v-col>
      <v-col cols="12" xl="5" lg="5" md="5" sm="12">
        <v-text-field v-model="page.path" label="Path"></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12">
        <v-checkbox
          v-model="page.includeInSiteMap"
          label="Include In Sitemap"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-card elevation="12">
      <v-card-title>
        <span class="text-h5">Meta</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="page.metaTitle"
                  label="Title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  :items="roles"
                  item-text="roleName"
                  item-value="id"
                  label="Authorize"
                  v-model="selectedRoles"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="8" lg="8" md="8" sm="12">
            <h3>Meta Tags</h3>
            <v-data-table :headers="metaHeaders" :items="metaTags">
              <template v-slot:header.edit>
                <v-btn outlined @click="onEditTag(0)" v-if="page.id > 0">
                  <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn outlined @click="onEditTag(item.id)">
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn outlined @click="onDeleteTag(item.id)">
                  <v-icon left>mdi-delete</v-icon>Delete
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="action-buttons">
      <v-spacer></v-spacer>
      <v-btn outlined class="button" @click="onCancel()">
        <v-icon left>mdi-cancel</v-icon>Cancel
      </v-btn>
      <v-btn outlined class="button" @click="onSave()">
        <v-icon left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
    <v-row>
      <h2>Sections</h2>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="sections">
          <template v-slot:header.edit>
            <v-btn outlined @click="onEdit(0)" v-if="page.id > 0">
              <v-icon left>mdi-plus</v-icon>Add
            </v-btn>
          </template>
          <template v-slot:item.type="{ item }">
            <label v-if="item.type==1">Text</label>
            <label v-if="item.type==2">Image</label>
            <label v-if="item.type==3">Image & Text</label>
            <label v-if="item.type==4">Carousel</label>
            <label v-if="item.type==5">Card</label>
            <label v-if="item.type==6">Cards</label>
            <label v-if="item.type==7">Video</label>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn outlined @click="onEdit(item.id)">
              <v-icon left>mdi-pencil</v-icon>Edit
            </v-btn>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-btn outlined @click="onDelete(item.id)">
              <v-icon left>mdi-delete</v-icon>Delete
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="metaDialog" persistent max-width="600">
      <v-card>
        <v-card-title>{{ metaTitle }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-text-field
                v-model="metaTag.property"
                label="Property"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-text-field
                v-model="metaTag.content"
                label="Content"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="button" text @click="metaDialog = false"
              >Cancel</v-btn
            >
            <v-btn class="button" text @click="onSaveMetaTag">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete section?</v-card-title>
        <v-card-text>{{ title }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">No</v-btn>
          <v-btn text @click="deleteSection">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import router from "@/router"
import store from "@/store"

export default {
  components: {},

  created() {
    console.log("Page.created()");
    this.get();
  },

  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Id",
        align: "end",
        groupable: false,
        sortable: true,
        value: "id",
      },
      {
        text: "Type",
        align: "start",
        groupable: false,
        sortable: true,
        value: "type",
      },
      {
        text: "Order",
        align: "end",
        groupable: false,
        sortable: true,
        value: "order",
      },
      {
        text: "Color",
        align: "start",
        groupable: false,
        sortable: true,
        value: "backgroundColor",
      },
      {
        text: "",
        align: "start",
        groupable: false,
        sortable: false,
        value: "edit",
      },
      {
        text: "",
        align: "start",
        groupable: false,
        sortable: true,
        value: "delete",
      },
    ],
    metaHeaders: [
      {
        text: "Id",
        align: "end",
        groupable: false,
        sortable: true,
        value: "id",
      },
      {
        text: "Property",
        align: "end",
        groupable: false,
        sortable: true,
        value: "property",
      },
      {
        text: "Content",
        align: "end",
        groupable: false,
        sortable: true,
        value: "content",
      },
      {
        text: "",
        align: "start",
        groupable: false,
        sortable: false,
        value: "edit",
      },
      {
        text: "",
        align: "start",
        groupable: false,
        sortable: true,
        value: "delete",
      },
    ],
    metaDialog: false,
    metaTag: {
      id: 0,
      property: "",
      content: "",
      pageId: 0,
    },
    metaTags: [],
    metaTitle: "Add Meta Tag",
    page: {
      id: 0,
      name: "",
      path: "",
      includeInSiteMap: true,
      metaTitle: "",
    },
    roles: ["Administrator", "Blogger"],
    sections: [],
    selectedRoles: [],
    title: "",
  }),

  methods: {
    deleteSection() {
      this.dialog = false;
      if (this.id > 0) {
        const url = "section/" + this.id;
        apiService.delete(url, this.deleteSuccess, this.deleteError);
      }
    },

    deleteSuccess(){
      const notification = {
        title: "Section Deleted",
        status: "success",
      };
      store.commit("setNotification", notification);
      this.get();
    },

    deleteError(error) {
      const notification = {
        title: "Problem deleting the section",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    get() {
      if (this.$route.params.id > 0) {
        const url = "roles";
        apiService.get(url, this.getRolesSuccess, this.getRolesError);
      }
    },

    getRolesSuccess(result) {
      this.roles = result.data;
      const url = "page/" + this.$route.params.id;
      apiService.get(url, this.getPageSuccess, this.getPageError);
    },

    getRolesError(error) {
      const notification = {
        title: "Error getting the Roles",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getPageSuccess(result) {
      this.page = result.data;
      this.metaTags = this.page.metaTags;
      this.setSelectedRoles();
      const url = "section/getSections/" + this.page.id;
      apiService.post(url, {}, this.getSectionSuccess, this.getSectionError);
    },

    setSelectedRoles() {
      this.selectedRoles = [];
      this.page.authorizedRoles.forEach((role) => {
        this.selectedRoles.push(role.id);
      });
    },

    getPageError(error) {
      console.log(error);
      const notification = {
        title: "Error getting the Page",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSectionSuccess(result) {
      this.sections = result.data;
    },

    getSectionError(error) {
      const notification = {
        title: "Error getting the Pages Sections",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onCancel() {
      router.go(-1);
    },

    onDelete(id) {
      this.dialog = true
      // Find the blog
      const section = this.sections.find((n) => n.id === id)
      this.title = ""
      this.id = 0
      if (section) {
        this.id = section.id
        this.title = "Delete the section with order " + section.order
      }
    },

    onEdit(id) {
      store.commit("setPageId", this.page.id);
      const to = "/pages/section/" + id;
      router.push(to);
    },

    onSave() {
      this.getSelectedRoles();
      let url = "page";
      if (this.page.id > 0) {
        url += "/" + this.page.id;
        apiService.put(url, this.page, this.saveSuccess, this.saveError);
      } else {
        apiService.post(url, this.page, this.saveSuccess, this.saveError);
      }
    },

    getSelectedRoles() {
      this.page.authorizedRoles = [];
      this.selectedRoles.forEach((id) => {
        let role = this.roles.find((r) => r.id == id);
        if (role) {
          this.page.authorizedRoles.push(role);
        }
      });
    },

    saveSuccess(result) {
      this.page = result.data;
      router.go(-1);
    },

    saveError(error) {
      const notification = {
        title: "Error saving the Page data",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onDeleteClosed(statusCode) {
      if (statusCode === 200 || statusCode === 201) {
        this.get();
      }
    },

    onEditTag(id) {
      this.metaTitle = id == 0 ? "Add Meta Tag" : "Edit Meta Tag";
      this.metaDialog = true;
      if (id == 0) {
        console.log("id is 0");
        this.metaTag = {
          id: 0,
          property: "",
          content: "",
          pageId: this.page.id,
        };
      } else {
        this.metaTag = this.metaTags.find((t) => t.id == id);
      }
    },

    onSaveMetaTag() {
      let url = "metatag";
      if (this.metaTag.id > 0) {
        url += "/" + this.metaTag.id;
        apiService.put(
          url,
          this.metaTag,
          this.saveMetaTagSuccess,
          this.saveMetaTagError
        );
      } else {
        apiService.post(
          url,
          this.metaTag,
          this.saveMetaTagSuccess,
          this.saveMetaTagError
        );
      }
    },

    saveMetaTagSuccess(result) {
      this.metaTag = result.data;
      this.metaDialog = false;
      var tag = this.metaTags.find((t) => t.id == this.metaTag.id);
      if (tag) {
        tag = this.metaTag;
      } else {
        this.metaTags.push(this.metaTag);
      }
    },

    saveMetaTagError(error) {
      const notification = {
        title: "Error saving the Meta Tag",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
</style>